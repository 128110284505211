<template>
  <transition name="fade">
    <div v-show="showDialog" class="onboarding text-center">
      <h1 class="onboarding__title mt-5 mb-8">ショッピングガイド</h1>

      <div class="d-flex justify-center align-center">
        <div class="mr-5">
          <img
            src="~/assets/images/onboarding/shopping_guide_1.png"
            width="137"
            height="133"
            alt=""
          />
          <div class="onboarding__guide-text mt-4">
            1.スマホで<br />商品を選ぶ
          </div>
        </div>
        <div>
          <img
            src="~/assets/images/onboarding/shopping_guide_2.png"
            width="137"
            height="137"
            alt=""
          />
          <div class="onboarding__guide-text mt-4">
            2.店舗レジで<br />お支払い
          </div>
        </div>
      </div>

      <div class="d-flex justify-center align-center mt-4 mb-8">
        <div>
          <img
            src="~/assets/images/onboarding/shopping_guide_3.png"
            width="137"
            height="96"
            alt=""
          />
          <div class="onboarding__guide-text mt-4">3.その場で受け取り</div>
        </div>
      </div>

      <v-btn
        color="primary"
        width="280"
        height="64"
        class="onboarding__start-button"
        @click="onClickGoShoppingBtn"
      >
        お買い物を始める
      </v-btn>
      <p class="onboarding__notice-message mt-4">
        このサイトは、SafariやChromeなどの<br />
        Webブラウザでの閲覧を推奨しています。
      </p>
    </div>
  </transition>
</template>

<script lang="ts">
export default defineComponent({
  computed: {
    showDialog(): boolean {
      return this.$store.getters['onboarding/showDialog']
    },
  },

  methods: {
    onClickGoShoppingBtn() {
      this.$store.commit('onboarding/closeDialog')
    },
  },
})
</script>

<style lang="sass" scoped>
button
  outline: none

.onboarding
  position: fixed
  z-index: 1111111
  width: 100vw
  height: 100vh
  background: $main-bg-color

.onboarding__title
  font-size: 28px

.onboarding__guide-text
  font-size: 18px
  font-weight: bold

.onboarding__start-button
  display: block
  margin: 0 auto
  font-size: 18px
  border-radius: 10px
  font-weight: 600
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.2)

.onboarding__notice-message
  font-size: 14px

.fade-enter-active,
.fade-leave-active
  transition: opacity 0.4s

.fade-enter, .fade-leave-to
  opacity: 0
</style>
